import React from "react";

export const Resume = () => {
  return (
    <div>
      <iframe
        src={"https://docs.google.com/document/d/e/2PACX-1vQK91TTlkDSZuYV739zAiJy9IzxqfSDvLN9q5z_2DSiRIdkXdw0VVkUCtAQJNv_wF8lh2fNHT-_Podd/pub"}
        title="file"
        width="100%"
        height="1300"
      ></iframe>
    </div>
  );
};

